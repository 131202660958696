import Loader from './src/loaders/loaders.volume'
import ModelsFrame from './src/models/models.frame'
import ModelsStack from './src//models/models.stack'
import { roundToFloat16Bits } from '../utils/HalfFloat'

// warning constants
const kWarningPixelSizeLimit = 0.3
const kBadPixelSize = 0.4

const kVolumeMinDimension = 0.4


export interface ILoadOptions {
    stateChange?(event: string, content: string): void;
    timeout?: number
    timeOutCB?(): void
    progressCB?(pcnt: number, info?: number): void
    ignoreData?: boolean
    normalizeOrientation?: boolean
    calcMinMax?: boolean
    fp16Output?: boolean
    //  ignoreSizeRestrictions?: boolean
    maxSize?: number                //max size of a dimension

}

export interface IFile {
    name: string
    buffer: Uint8Array
}
export enum EError {
    NODCM = 'NODCM',
    MULTIPLECT = 'MULTIPLECT',
    NOTGRAYSCALE = 'NOTGRAYSCALE',
    BADORIENTATION = 'BADORIENTATION',
    SLICEMISSING = 'SLICEMISSING',
    BADSLICETHICKNESS = 'BADSLICETHICKNESS',
    EXCEPTION = 'EXCEPTION',
    UNKNOWNCOMPRESSION = 'UNKNOWNCOMPRESSION',
    PIXELRESOLUTIONTOOLOW = 'PIXELRESOLUTIONTOOLOW',
    //FILEFORMATERROR = 'FILEFORMATERROR'
}
export enum EWarning {
    HASLOSSY = 'HASLOSSY',
    VOLUMESIZE = 'VOLUMESIZE',
    PIXELRESOLTIONNOTOPTIMAL = 'PIXELRESOLTIONNOTOPTIMAL'
}
export interface LoadInfo {
    errors: { [key: string]: { text?: string, param?: string, param2?: string } }
    warnings: { [key: string]: { text?: string, param?: string, param2?: string } }
    info?: any
}
export class Dicom implements LoadInfo {
    errors: { [key: string]: { text?: string, param?: string, param2?: string } } = {}
    warnings: { [key: string]: { text?: string, param?: string, param2?: string } } = {}

    buffer?: Int16Array
    width?: number;
    height?: number;
    imageCount?: number;
    min?: number;
    max?: number;
    orientation?: number[];
    spacingBetweenSlice?: number;
    voxelSize?: number[];
    imagePositionPatientFirst?: number[];
    imagePositionPatientLast?: number[];
    windowCenter?: number;
    windowWidght?: number;
    slope?: number;
    intercept?: number;
    compression?: string;
    hasLossy: boolean = false;
    hasCompressed: boolean = false;
    patientName: string = ''
    addError(code: EError, text: string, param?: any, param2?: any) {
        this.errors[code] = {
            text,
            param: param ? String(param) : undefined,
            param2: param2 ? String(param2) : undefined
        }
    }
    addWarning(code: EWarning, text: string, param?: any, param2?: any) {
        this.warnings[code] = {
            text,
            param: param ? String(param) : undefined,
            param2: param2 ? String(param2) : undefined
        }
    }
    get hasErrors(): boolean {
        return Object.keys(this.errors).length > 0
    }
}

export async function loadDicom(taskName: string, files: IFile[], _options?: ILoadOptions): Promise<Dicom> {

    let options = {
        ...{
            stateChange: () => { },
            progressCB: (pcnt: number) => { },
            ignoreData: false,
            normalizeOrientation: true,
            calcMinMax: true,
            fp16Output: false,
            ignoreSizeRestrictions: false

        }, ...(_options ? _options : {})
    }
    //console.log('o2:'+JSON.stringify(options))
    const loader = new Loader();
    let resultStacks: any[] = [];
    let resultStack;
    let w;
    let h;
    let min = 1000000 | 0;
    let max = -1000000 | 0;
    let convDiff = 0;
    let timeout;
    let result = new Dicom();

    if (options.fp16Output) {
        //fp16 requires minmax value
        options.calcMinMax = true;
    }
    options.stateChange('started', `${new Date()}\n${taskName}`)

    if (options.timeout) {
        timeout = setTimeout(() => {
            options.stateChange('timeout', `${new Date()}\n${taskName}`)
            options.timeOutCB?.();
        },
            options.timeout
        );
    }
    const kLoadPartWeight = (options.fp16Output ? 90 : 100) - 25;
    try {
        for (let k = 0; k < files.length; k++) {
            let parsed
            try {
                parsed = await loader.parse(files[k].buffer, options.ignoreData, options.normalizeOrientation);
                files[k].buffer = new Uint8Array()
                options.progressCB(25 + (k / files.length) * kLoadPartWeight, k)
            } catch (e) {
                // result.addError(EError.FILEFORMATERROR, '')
                continue;
            }

            if (parsed.stack[0].frame.length === 0)
                continue;

            result.hasCompressed = result.hasCompressed || parsed.hasCompressed;
            result.hasLossy = result.hasLossy || parsed.hasLossy;
            result.patientName = parsed.patientName

            for (let stacks = 0; stacks < parsed.stack.length; stacks++) {
                let actStack = parsed.stack[stacks] as ModelsStack;
                for (let j = 0; j < actStack.frame.length; j++) {
                    let frame = actStack.frame[j];
                    w = frame.columns | 0;
                    h = frame.rows | 0;
                    if (frame._compressionMethod === 'Unknown') {
                        result.addError(EError.UNKNOWNCOMPRESSION, '')
                        // remove unkown frame
                        actStack.frame.splice(j);
                        j--
                        continue;
                    }
                    if (!frame.pixelData) {
                        actStack.frame.splice(j);
                        j--
                        continue;
                    }
                    result.compression = frame._compressionMethod;
                    let convBuf;
                    let b: Int16Array = frame.pixelData;

                    if (frame.pixelRepresentation === 0) {
                        convDiff = -32768;
                        //signed short, we need to convert it

                        convBuf = new Int16Array(w * h);
                        for (let i = 0 | 0; i < w * h; i++) {
                            convBuf[i] = frame.pixelData[i] - 32768;           //unsigned to signed
                        }
                        frame.pixelData = null
                    } else {
                        convBuf = frame.pixelData;
                    }

                    frame.convertedBuffer = convBuf;
                    frame.fileName = files[k].name;
                    b = convBuf;
                    if (options.calcMinMax) {
                        if (frame._minMax) {
                            min = Math.min(min, frame._minMax[0] + convDiff)
                            max = Math.max(max, frame._minMax[1] + convDiff);
                        } else {
                            let maxSize = (w * h) | 0
                            for (let i = 0 | 0; i < maxSize; i++) {
                                min = Math.min(min, b[i] | 0)
                                max = Math.max(max, b[i] | 0);
                            }
                        }
                    }
                }

                let mergeSucc = false
                if (resultStacks.length) {
                    for (let j = 0; j < resultStacks.length; j++) {
                        if (resultStacks[j].merge(parsed)) {
                            mergeSucc = true
                            break;
                        }
                    }
                }
                if (!mergeSucc) {
                    resultStacks.push(parsed)
                }
            }
        }


        if (!resultStacks.length) {
            result.addError(EError.NODCM, 'no valid DCM file found')

        } else {
            if (resultStacks.length !== 1) {
                result.addError(EError.MULTIPLECT, 'multiple ct-s upload {1}', resultStacks.length)
            } else {
                resultStack = resultStacks[0] as ModelsStack


                if (resultStack.stack.length !== 1) {
                    result.addError(EError.MULTIPLECT, 'multiple ct-s upload {1}', resultStack.stack.length)
                }
                resultStack.stack[0].frame.sort((a, b) => a.imagePosition[2] - b.imagePosition[2])

                let f = resultStack.stack[0].frame[0] as ModelsFrame;
                //result.compression = resultStack

                if (f.numberOfChannels !== 1) {
                    result.addError(EError.NOTGRAYSCALE, 'not a grayscale image, number of channels {1}', f.numberOfChannels)
                }
                if (JSON.stringify(f.imageOrientation) !== JSON.stringify([1, 0, 0, 0, 1, 0])) {
                    result.addError(EError.BADORIENTATION, 'bad CT configuration slice orientation is not identity {1}', JSON.stringify(f.imageOrientation))
                } else {
                    //atnezni, hogy a tavolsagok kozisztensek-e (esetleg lyukas)
                    //ha amugy konzisztensek akkor megnezni, hogy ugyanaz-e mint a slicethickness
                    let zPos;
                    let lastDist;
                    let wasSliceError = false;
                    for (let k = 1; k < resultStack.stack[0].frame.length; ++k) {
                        let f0 = resultStack.stack[0].frame[k];
                        let f1 = resultStack.stack[0].frame[k - 1];
                        let dist = Math.abs(f1.imagePosition[2] - f0.imagePosition[2]);
                        if (k > 1) {
                            if (Math.abs(dist - lastDist) > 0.0001) {
                                if (!wasSliceError) {
                                    result.addError(EError.SLICEMISSING, 'slice is missing from {1}', k)
                                    wasSliceError = true;
                                }
                            }
                        }
                        lastDist = dist;
                    }

                    if (Math.abs(lastDist - f.sliceThickness) > 0.0001) {
                        result.addError(EError.BADSLICETHICKNESS, `slice thickness is not equal to slice distance (thickness:{1}, distance:{2}`, f.sliceThickness.toFixed(2), lastDist.toFixed(2))
                    }
                }
            }
        }
    } catch (e: any) {
        result.addError(EError.EXCEPTION, e.stack)
    }
    if (options.calcMinMax) {
        result.min = min;
        result.max = max;
    }
    if (result.hasErrors) {
        if (timeout) {
            clearTimeout(timeout)
        }
        return result;
    }

    let frame = resultStack.stack[0].frame[0];
    result = Object.assign(result, {
        width: w,
        height: h,
        imageCount: resultStack.stack[0].frame.length,
        originalVoxelSize: [
            frame.pixelSpacing[0],
            frame.pixelSpacing[1],
            frame.sliceThickness
        ],
        voxelSize: [
            frame.pixelSpacing[0],
            frame.pixelSpacing[1],
            frame.sliceThickness
        ],
        spacingBetweenSlice: frame.spacingBetweenSlice,
        orientation: [
            frame.imageOrientation[0],
            frame.imageOrientation[1],
            frame.imageOrientation[2],
            frame.imageOrientation[3],
            frame.imageOrientation[4],
            frame.imageOrientation[5],
        ],
        imagePositionPatientFirst: [
            resultStack.stack[0].frame[0].imagePosition[0],
            resultStack.stack[0].frame[0].imagePosition[1],
            resultStack.stack[0].frame[0].imagePosition[2]
        ],
        imagePositionPatientLast: [
            resultStack.stack[0].frame[resultStack.stack[0].frame.length - 1].imagePosition[0],
            resultStack.stack[0].frame[resultStack.stack[0].frame.length - 1].imagePosition[1],
            resultStack.stack[0].frame[resultStack.stack[0].frame.length - 1].imagePosition[2],
        ],
        windowCenter: frame.windowCenter + convDiff,
        windowWidth: frame.windowWidth,
        slope: frame.rescaleSlope,
        intercept: frame.rescaleIntercept,

    })
    //console.log('ro:'+JSON.stringify(result))
    let c = resultStack.stack[0].frame.length;
    // realign [min..max] to [1.0, 1.0 + (max-min)/kOneTwoMinRange ] range for better fp16 precision/compression (better mantissa values ~1..2 (1/2^10))

    if(result.width && result.height && result.voxelSize && result.imageCount) {

        let volume //= new Int16Array(w * h * c)
        const maxDim = Math.max(Math.max(result.width, result.height), result.imageCount);
        const minDim = Math.min(Math.min(result.width, result.height), result.imageCount);
        const maxPixelResolution = Math.max(Math.max(result.voxelSize[0], result.voxelSize[1]), result.voxelSize[2]);
        //console.log(`options:`+JSON.stringify(options))

        if (options.fp16Output) {
            if (options.maxSize && options.maxSize < maxDim) {
                let scale = Math.trunc(maxDim / options.maxSize) + 1;
                let scaleDiv = scale * scale * scale;
                let w2 = Math.floor(w / scale);
                let h2 = Math.floor(h / scale);
                let c2 = Math.floor(c / scale);
                let minIn64k = min + 32768.0;
                result.width = w2;
                result.height = h2;
                result.imageCount = c2;

                result.voxelSize = result.voxelSize.map(x => x * scale)
                volume = new Uint16Array(w2 * h2 * c2)

                for (let z = 0 | 0; z < c2; z++) {
                    for (let y = 0 | 0; y < h2; y++) {
                        for (let x = 0 | 0; x < w2; x++) {
                            let sum = 0;
                            for (let z0 = 0; z0 < scale; z0++) {
                                let fi = resultStack.stack[0].frame[z0 + z * scale]
                                for (let y0 = 0; y0 < scale; y0++) {
                                    let posInRow = (y * scale + y0) * w + x * scale;
                                    for (let x0 = 0; x0 < scale; x0++) {
                                        sum += fi.convertedBuffer[x0 + posInRow];
                                    }
                                }
                            }
                            let avg = Math.fround(sum / scaleDiv);
                            let numZeroTo64k = (avg + 32768 | 0);
                            let reloc = 1.0 + (numZeroTo64k - minIn64k);// / floatDiv;
                            volume[x + y * w2 + z * (w2 * h2)] = roundToFloat16Bits(reloc)
                        }
                    }
                    options.progressCB(90 + z / c2 * 10)
                }

            } else {
                volume = new Uint16Array(w * h * c)
                let base = 0;
                let minIn64k = min + 32768.0;
                for (let k = 0 | 0; k < c; k++) {
                    let f = resultStack.stack[0].frame[k];
                    let b: Int16Array = f.convertedBuffer;
                    let bl = b.length | 0;
                    options.progressCB(90 + k / c * 10)
                    for (let j = 0 | 0; j < bl; j++, base++) {
                        let numZeroTo64k = (b[j] + 32768 | 0);
                        let reloc = 1.0 + (numZeroTo64k - minIn64k);// / floatDiv;
                        volume[base] = roundToFloat16Bits(reloc)
                    }
                }
            }
        } else {
            volume = new Int16Array(w * h * c)
            for (let k = 0; k < c; k++) {
                let f = resultStack.stack[0].frame[k];
                let b: Int16Array = f.convertedBuffer;
                volume.set(b, w * h * k);
            }
        }
        if (minDim < maxDim * kVolumeMinDimension) {
            result.addWarning(EWarning.VOLUMESIZE, 'volume size is suspicious, please make sure all files are uploaded', minDim / maxDim)
        }
        if (result.hasLossy) {
            result.addWarning(EWarning.HASLOSSY, 'CT is compressed with lossy compression, for better quality please use a lossless compression or uncompressed format')
        }

        if (maxPixelResolution > kWarningPixelSizeLimit) {
            result.addWarning(EWarning.PIXELRESOLTIONNOTOPTIMAL, `pixel resolution is ${maxPixelResolution}, for better result try to capture with higher resolution less than {1} `, kWarningPixelSizeLimit.toFixed(2))
        }
        if (maxPixelResolution > kBadPixelSize) {
            result.addError(EError.PIXELRESOLUTIONTOOLOW, `pixel resolution is ${maxPixelResolution}, please reuplod with higher resolution (less than {1}, for optimal resolution, use less {2})`, kBadPixelSize.toFixed(2), kWarningPixelSizeLimit.toFixed(2))
        }
        result.buffer = volume;
    }

    clearTimeout(timeout)
    return result;
}